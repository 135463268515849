import React from "react";
import Footer from "../../components/Footer";

const Electrical: React.FC = () => {
  const categories = [
    "Bare Wire",
    "Blank Cover Plate Indoor",
    "Boxes and Rings",
    "Breaker Box Parts",
    "Breakers",
    "Building Wire",
    "Bushing and Lock Nut Insulating and Grounded",
    "Bushing and Lock Nut Pass Though Metal Bushing",
    "Bushing and Lock Nut Thread Protection Bushing",
    "Can Light Boxes",
    "Can Light Gimbal",
    "Can Light Housing",
    "Can Light Remodel",
    "Can Light Trim",
    "Conduit",
    "Conduit Offset EMT to Rigid",
    "Cover Plate Indoor",
    "Covers Outdoor",
    "Covers Steel Indoor",
    "Data Junction Box",
    "Data Wall Plate",
    "Decora Switch",
    "Detector",
    "Dimmer Switch",
    "Disconnect Switch",
    "EMT 45",
    "EMT 90",
    "EMT Connector Indoor Insulated",
    "EMT Connector Indoor Non Insulated",
    "EMT Connector Outdoor Insulated",
    "EMT Connector Outdoor Non Insulated",
    "EMT Coupling Indoor",
    "EMT Coupling Outdoor",
    "EMT Inside Corner Pulling Elbow",
    "EMT Offset Connector",
    "EMT Pulling Elbow",
    "EMT Strap",
    "EMT Type LB Conduit Body",
    "EMT to Rigid Pulling Elbow",
    "ENT Adapter",
    "EV Chargers",
    "Exaust Fan",
    "FMC 90 Connector",
    "FMC Coupling",
    "FMC Screw In Connector",
    "FMC Straight Connector",
    "LED Ballast",
    "Liquidtight Connectors",
    "MC 90 Connector",
    "MC Bushing",
    "MC Duplex Connector",
    "MC Straight Connector",
    "MC Strap",
    "MC Wire",
    "Main Breaker",
    "Main Breaker Load Center",
    "Main Lug Load Center",
    "Meter Main Breakers",
    "Meter Main Load Center",
    "Misc",
    "Motion Sensor",
    "NEMA Outlets",
    "Outdoor Junction Box",
    "Outdoor Wire",
    "Outlet",
    "Outlets",
    "PVC 45",
    "PVC 90",
    "PVC Adapter",
    "PVC Coupling",
    "PVC LB",
    "PVC LL",
    "PVC LR",
    "PVC Reducing Bushing",
    "PVC Strap",
    "PVC T",
    "RV Panels",
    "Rigid 45",
    "Rigid 90",
    "Rigid C",
    "Rigid Chase Nipple",
    "Rigid Close Nipple",
    "Rigid Compression Connector",
    "Rigid Conduit Offset",
    "Rigid Connector",
    "Rigid Coupling",
    "Rigid Hub",
    "Rigid LB",
    "Rigid LL",
    "Rigid LR",
    "Rigid Nipple",
    "Rigid Pulling Elbow",
    "Rigid Reducing Bushing",
    "Rigid Reducing Washer",
    "Rigid Reducing Washing",
    "Rigid Strap",
    "Rigid T",
    "Romex Metal Connector",
    "Romex Plastic Connector",
    "S/O Cord",
    "S/O Cord Strain Relief Connector",
    "Service Entrance",
    "Service Entrance Wire",
    "Smart Switches",
    "Spa Panels",
    "Specialty Connector EMT to Rigid",
    "Specialty Coupling EMT to FMC",
    "Specialty Coupling EMT to Romex",
    "Specialty Strap EMT Drive Strap",
    "Specialty Strap Metal",
    "Strut",
    "Strut Strap",
    "Surege Protector",
    "Timers",
    "Toggle Switch",
    "Tunnel Kit",
    "Ventilation",
    "Wiring Parts",
  ];

  return (
    <div className="container" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <section
        style={{
          backgroundColor: "#00274d",
          color: "#ffffff",
          padding: "100px 20px 60px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "center",
            gap: "20px",
            maxWidth: "1200px",
            width: "100%",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#ffffff" }}>Our AccuPrice Electrical List</h1>
            <p style={{ fontSize: "1.2rem", lineHeight: "1.8", marginBottom: "20px" }}>
              AccuPrice was built by an electrician who was tired of manually checking prices at big box stores
              every time they needed to create an estimate or invoice. This platform is designed to simplify
              that process by providing a comprehensive list of electrical items, categorized and updated
              monthly.
            </p>
            <p style={{ fontSize: "1.2rem", lineHeight: "1.8", marginBottom: "20px" }}>
              Subscribers to AccuPrice gain access to a seamless tool for downloading or syncing prices
              directly into their estimating and invoicing systems, ensuring accuracy and saving valuable
              time. Whether you're a contractor or project manager, our detailed categories make it easy to
              find exactly what you need without the hassle of outdated pricing.
            </p>
            <p style={{ fontSize: "1.6rem", fontWeight: "bold", lineHeight: "1.8" }}>
              The electrical catalog provides nearly 1200 hand-selected items that have been named for
              searchability. Below are many of the categories that make up the list:
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="/images/electrical.png"
              alt="Electrical Catalog Preview"
              style={{ maxWidth: "100%", borderRadius: "8px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)" }}
            />
          </div>
        </div>
      </section>
      <div className="content" style={{ flex: "1 0 auto", padding: "40px 20px" }}>
        <div
          className="categories"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: "20px",
          }}
        >
          {categories.map((category, index) => (
            <div
              key={index}
              className="category-item"
              style={{
                padding: "20px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                textAlign: "center",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
              }}
              onMouseEnter={(e) => {
                const target = e.currentTarget;
                target.style.transform = "scale(1.05)";
                target.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                const target = e.currentTarget;
                target.style.transform = "scale(1)";
                target.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
              }}
            >
              <h4 style={{ margin: "0 0 10px", fontSize: "18px", color: "#00274d" }}>{category}</h4>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Electrical;