import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuickBooksCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleQuickBooksCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code'); // Authorization code from QuickBooks
      const state = urlParams.get('state'); // State, if you use it
      const realmId = urlParams.get('realmId');

      if (!code) {
        console.error('Authorization code not found');
        return;
      }

      try {
        // Exchange authorization code for an access token
        const response = await axios.post(`${config.apiBaseUrl}/quickbooks/auth/`, { code });
        const accessToken = response.data.access_token;
        
        // Set the expiration time to 60 minutes from now
        const expiresAtDate = new Date(Date.now() + 50 * 60 * 1000);
        
        toast.success('Authentication completed, now you can sync');
        // Save access_token to localStorage (or sessionStorage for more security)
        localStorage.setItem('quickbooks_access_token', accessToken);
        localStorage.setItem('quickbooks_token_expires_at', expiresAtDate.toISOString());
        localStorage.setItem('quickbooks_company_id', realmId || '');

        // Navigate back to the sync page or dashboard
        // navigate('/dashboard');
        setTimeout(() => navigate('/dashboard'), 3000);
      } catch (error) {
        console.error('Failed to exchange authorization code for token', error);
      }
    };

    handleQuickBooksCallback();
  }, [navigate]);

  return <div><ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
    Processing authentication...</div>;
};

export default QuickBooksCallback;
