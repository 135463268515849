import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import config from '../config';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../src/assets/css/login.css';

type ErrorResponse = {
  [key: string]: string[];
};

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  };

  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors([]);

    try {
      const response = await axios.post(`${config.apiBaseUrl}/login_page/`, {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        console.log('Login successful:', response.data);
        localStorage.setItem('userSession', JSON.stringify(response.data));
        localStorage.setItem('email', email); 
        localStorage.setItem('status', response.data['status']); 
        localStorage.setItem('verified', response.data['is_verified']); 
        localStorage.setItem('update1', response.data['update1']); 
        localStorage.setItem('update2', response.data['update2']); 
        toast.success('login successfull.');
        setTimeout(() => navigate('/dashboard'), 5000);
        // navigate('/dashboard');
      } else {
        alert('Login failed');
      }
    } catch (err) {
      const error = err as AxiosError<ErrorResponse>;
      console.error('There was an error logging in!', error);
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessages: string[] = [];

        for (const key in responseData) {
          if (responseData[key]) {
            errorMessages.push(`${key}: ${responseData[key]}`);
            toast.error(responseData[key]);
          }
        }

        if (errorMessages.length === 0) {
          errorMessages.push('There was an error logging in!');
        }

        setErrors(errorMessages);
      } else {
        setErrors(['There was an error logging in!']);
      }
    }
  };

  const handleGoogleSuccess = async (response: CredentialResponse) => {
    // console.log('Google OAuth Success:', response); // Log the response to verify it
    if (response.credential) {
      try {
        const googleResponse = await axios.post(`${config.apiBaseUrl}/google-login/`, {
          token: response.credential,
        });

        if (googleResponse.status === 200) {
          // console.log('Google Login successful:', googleResponse.data);
          // Save user session data to local storage
          localStorage.setItem('userSession', JSON.stringify(googleResponse.data));
          localStorage.setItem('email', googleResponse.data.email);
          localStorage.setItem('username', googleResponse.data.name);
          localStorage.setItem('status', googleResponse.data.status);
          localStorage.setItem('status2', googleResponse.data.status);
          localStorage.setItem('verified', googleResponse.data.is_verified);
          localStorage.setItem('update1', googleResponse.data.update1);
          localStorage.setItem('update2', googleResponse.data.update2);
          // Navigate to dashboard
          navigate('/dashboard');
        } else {
          alert('Google Login failed');
        }
      } catch (err) {
        console.error('There was an error logging in with Google!', err);
        setErrors(['There was an error logging in with Google!']);
        toast.error('There was an error logging in with Google!')
      }
    }
  };

  const handleGoogleFailure = () => {
    console.error('Google OAuth Failed'); // Log the failure
    setErrors(['Google login failed.']);
  };

  return (
    <div className="login-container">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
      <div className="left-side">
        <div className="welcome-text">
          <span className="circle"></span>
          <h1>Welcome back</h1>
          <p>We're glad to see you again</p>
        </div>
      </div>
      <div className="right-side">
        <div className="signup-link top-signup-link">
          <p className="back-to-home">
            Back to <Link to="/">Home</Link>
          </p>
        </div>
        <div className="login-box">
          <img src="/images/logo.png" alt="Accuprice Logo" className="login-screen-logo" />
          <h2 className='signin-heading'>Sign in</h2>
          <div className="social-login">
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
            />
            {/* <p className='google-button'>Continue with Google</p> */}
          </div>
          <p className="or">
            <span></span> OR <span></span>
          </p>
          {/* {errors.length > 0 && (
            <div className="error-messages">
              {errors.map((error, index) => (
                <div key={index} className="error-message">{error}</div>
              ))}
            </div>
          )} */}
          <form className='signin-form' onSubmit={handleLogin}>
            <div className="username">
              <label htmlFor="email">Email address</label>
              <input className='sigin-inputs' type="email" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="password-container">
              <label htmlFor="password">Your password</label>
              <input
                type={passwordVisible ? "text" : "password"}
                className='sigin-inputs'
                id="password"
                value={password}
                placeholder='Enter Your Password'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="toggle-password" onClick={togglePasswordVisibility}>
                <img
                  src={`/images2/icon-${passwordVisible ? "hide" : "show"}.png`}
                  alt={passwordVisible ? "Hide" : ""}
                />
                {passwordVisible ? " Hide" : " Show"}
              </span>
            </div>
            <Link to="/forgot-password" className="forgot-password">
              Forget your password?
            </Link>
            <button type="submit" className="signin-btn">
              Log in
            </button>
            <div className="social-login-bottom">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
              />
              {/* <p className='google-button'>Continue with Google</p> */}
            </div>
            <div className="signup-link-left">
              <p>
                Don't have an account? <Link to="/register">Sign up</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
