import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config'; 

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState<string>(localStorage.getItem('activeSection') || 'catalog');

  const toggleSidebar = () => {
    setIsCollapsed(prev => !prev);
    document.body.classList.toggle('sidebar-collapsed', !isCollapsed);
  };

  const handleLinkClick = (sectionId: string) => {
    setActiveSection(sectionId);
    localStorage.setItem('activeSection', sectionId);
  };

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setActiveSection('catalog');
      localStorage.setItem('activeSection', 'catalog');
    }
  }, [location.pathname]);

  useEffect(() => {
    const sections = document.querySelectorAll('.content-section');
    sections.forEach(section => {
      (section as HTMLElement).style.display = (section as HTMLElement).id === activeSection ? 'block' : 'none';
    });
  }, [activeSection]);

  const handleLogout = async () => {
    const response = await axios.get(`${config.apiBaseUrl}/logout/`);
      localStorage.removeItem('userSession'); 
      localStorage.removeItem('email'); 
      localStorage.removeItem('activeSection')
      localStorage.removeItem('avatarUrl')
      localStorage.removeItem('status')
      localStorage.removeItem('status2')
      localStorage.removeItem('update1')
      localStorage.removeItem('update2')
      localStorage.removeItem('username')
      localStorage.removeItem('verified')
    
    navigate('/'); // Redirect to home page
  };

  return (
    <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <style>{`
        .sidebar.collapsed {
          width: 60px;          
        }
        .collapsed .top-links span  {
          opacity: 0;
        }
        .collapsed .logout-section span  {
          opacity: 0;
        }
        .collapsed + .main-content {
          margin-left: 60px;
        }
      `}</style>
      <div>
        <div className={`logo ${isCollapsed ? 'collapsed' : ''}`}>
          <Link to="/">
            <img src="/images/logo.png" alt="Accuprice Logo" className="dashboard-logo" />
          </Link>
          <button className={`toggle-btn ${isCollapsed ? 'toggle-center' : ''}`} onClick={toggleSidebar}>
            <img src="/images2/chevrons-left.svg" alt="Toggle Sidebar" />
          </button>
        </div>
        <div className="line">
          <hr />
        </div>
        <ul className={`top-links ${isCollapsed ? 'ul-paddings-not' : ''}`}>
          <Link to="catalog" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li className={activeSection === 'catalog' ? 'active' : ''} data-section="catalog" onClick={() => handleLinkClick('catalog')}>
              <img className='sidebar-desktop-icon' src="/images2/home.svg" alt="Catalog Icon" />
              <img className='sidebar-mobile-icon' src="/images2/cataloge-mobile.png" alt="" />
              <span>Catalogs</span>
            </li>
          </Link>
          <Link to="userfactor" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li className={activeSection === 'configuration' ? 'active' : ''} data-section="configuration" onClick={() => handleLinkClick('configuration')}>
              <img className='sidebar-desktop-icon' src="/images2/Line_up.svg" alt="Configuration Icon" />
              <img className='sidebar-mobile-icon' src="/images2/configuration-mobile.png" alt="" />
              <span>Configurations</span>
            </li>
          </Link>
          <Link to="subscriptions" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li className={activeSection === 'subscriptions' ? 'active' : ''} data-section="subscriptions" onClick={() => handleLinkClick('subscriptions')}>
              <img className='sidebar-desktop-icon' src="/images2/scan.svg" alt="Subscriptions Icon" />
              <img className='sidebar-mobile-icon' src="/images2/subscription-mobile.png" alt="" />
              <span>Subscriptions</span>
            </li>
          </Link>
          <Link to="settings" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li className={activeSection === 'settings' ? 'active' : ''} data-section="settings" onClick={() => handleLinkClick('settings')}>
              <img className='sidebar-desktop-icon' src="/images2/light-icon-sidebar/SettingsIcon.png" alt="Settings Icon" />
              <img className='sidebar-mobile-icon' src="/images2/settings-mobile.png" alt="" />
              <span>Settings</span>
            </li>
          </Link>
          <Link className='user-guide-button' to="user-guide" style={{ textDecoration: 'none', color: 'inherit' }}>
            <li className={activeSection === 'user-guide' ? 'active' : ''} data-section="user-guide" onClick={() => handleLinkClick('user-guide')}>
              <img src="/images2/book.svg" alt="User Guide Icon" />
              <span>User Guide and Resources</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className='logout-section-container'>
      <ul className={`logout-section ${isCollapsed ? 'ul-paddings-not' : ''}`}>
        <div className="line">
          <hr />
        </div>
        <li className="logout" data-section="logout" onClick={handleLogout}>
          <img src="/images2/Sign_out_squre_light.svg" alt="Log Out Icon" />
          <span>Log Out</span>
        </li>
      </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
