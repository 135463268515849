import React, { useEffect, useState } from 'react';
import axios from "axios";
import config from "../config";
import {UserGuideResource} from "./types";
import {UserGuideResourceCard} from "./UserGuideResourceCard";

export const UserGuideResources: React.FC = () => {
    const [resources, setResources] = useState<UserGuideResource[]>([]);

    useEffect(() => {
        const fetchResources = async () => {
            try {
                const response = await axios.get(`${config.apiBaseUrl}/user-guide-resources/`);
                setResources(response.data);
            } catch (error) {
                console.error('Failed to fetch resources', error);
            }
        }
        fetchResources();
    }, []);

    return (
        <>
            {resources.map((resource) => (
                <UserGuideResourceCard resource={resource} />
            ))}
        </>
    );
};
