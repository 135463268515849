import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config'; // Ensure the config path is correct

const UserFactor: React.FC = () => {
  const [markup1_10, setMarkup1_10] = useState<string>('');
  const [markup11_20, setMarkup11_20] = useState<string>('');
  const [markup21_50, setMarkup21_50] = useState<string>('');
  const [markup51_150, setMarkup51_150] = useState<string>('');
  const [markup151_500, setMarkup151_500] = useState<string>('');
  const [markup501, setMarkup501] = useState<string>('');
  const [tax, setTax] = useState<string>('');
  const [inventoryOption, setInventoryOption] = useState<string>('');
  const [incomeType, setIncomeType] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserFactor = async () => {
      setLoading(true);
      setError(null);
      try {
        const email = localStorage.getItem('email');
        if (email) {
          const response = await axios.get(`${config.apiBaseUrl}/user-factor/`, {
            headers: {
              'X-User-Email': email,
            },
          });
          const data = response.data;

          // Set state with fetched data
          setMarkup1_10(data.markup1_10);
          setMarkup11_20(data.markup11_20);
          setMarkup21_50(data.markup21_50);
          setMarkup51_150(data.markup51_150);
          setMarkup151_500(data.markup151_500);
          setMarkup501(data.markup501);
          setTax(data.tax);
          setInventoryOption(data.inventory_option);
          setIncomeType(data.income_type);
        } else {
          setError('No email found in local storage');
          toast.error('No email found in local storage');
        }
      } catch (err) {
        setError('Failed to fetch user factor data');
        toast.error('Failed to fetch user factor data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserFactor();
  }, [success]);

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent form submission or page refresh
    
    setSuccess(null);
    setError(null);
  
    const data = {
      markup1_10,
      markup11_20,
      markup21_50,
      markup51_150,
      markup151_500,
      markup501,
      tax,
      inventory_option: inventoryOption,
      income_type: incomeType,
    };
  
    try {
      const email = localStorage.getItem('email');
      if (!email) {
        throw new Error('No email found in local storage');
      }
  
      const response = await axios.post(`${config.apiBaseUrl}/user_factor/`, data, {
        headers: {
          'X-User-Email': email,
        },
      });
      

      setSuccess('Your markups are saved! You are now ready to download your catalogs');
      toast.success('Your markups are saved! You are now ready to download your catalogs');
      console.log('User factor updated successfully:', response.data);
    } catch (error) {
      setError('Failed to update user factor');
      toast.error('Failed to update user factor');
      console.error('Failed to update user factor', error);
    }
  };
  
  return (
    <section id="configuration" className="content-section">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
      <div className="configuration-header">
        <nav className="configuration-nav">
          <p className="configuration-link">User Configuration</p>
        </nav>
      </div>
      <form className="configuration-container">
        <h1 className="configuration-title">Mark ups</h1>
        <div className="markup-list">
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 0 - 10' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup1_10}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '') setMarkup1_10(e.target.value);
                  }}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 10 - 20' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup11_20}
                  onChange={(e) =>{
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '')  setMarkup11_20(e.target.value)}}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 20 - 50' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup21_50}
                  onChange={(e) =>{
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '')  setMarkup21_50(e.target.value)}}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 50 - 150' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup51_150}
                  onChange={(e) =>{
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '')  setMarkup51_150(e.target.value)}}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 150 - 500' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup151_500}
                  onChange={(e) =>{
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '')  setMarkup151_500(e.target.value)}}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
          <div className="markup-item">
            <div className="left-side-inputs">
              <input type="text" className="range-input" value='$ 500 +' readOnly />
            </div>
            <div className="right-side-inputs">
              <div className="input-button-wrapper">
                <input
                  type="number"
                  className="type-input"
                  value={markup501}
                  onChange={(e) =>{
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === '')  setMarkup501(e.target.value)}}
                  placeholder="Type..."
                  min={0}
                />
                <button type="button" className="add-button" >%</button>
              </div>
            </div>
          </div>
        </div>
        <div className="optional-fields">
          <div className="input-container" data-label="Sales Tax">
            <input
              type="number"
              className="optional-input"
              value={tax}
              onChange={(e) =>{
                const value = parseFloat(e.target.value);
                if (value >= 0 || e.target.value === '')  setTax(e.target.value)}}
              placeholder="Sales Tax (optional)"
              min={0}
            />
            <button type="button" className="add-button" >%</button>
          </div>
          <div className="input-container" data-label="Inventory options">
            <input
              type="text"
              className="optional-input"
              value={inventoryOption}
              onChange={(e) => setInventoryOption(e.target.value)}
              placeholder="Inventory options (optional)"
              maxLength={25}
            />
          </div>
          <div className="input-container" data-label="Income Type">
            <input
              type="text"
              className="optional-input"
              value={incomeType}
              onChange={(e) => setIncomeType(e.target.value)}
              placeholder="Income Type (optional)"
              maxLength={25}
            />
          </div>
        </div>
        
        <div className="bottom-butons">
        {/* <button className="cancel">Cancel</button> */}
        <button className="save" onClick={handleSave}>Save</button>
      </div>
      </form>
    </section>
  );
};

export default UserFactor;
