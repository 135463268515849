import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import {UserGuideResource} from "./types";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


interface UserGuideResourceCardProps {
    resource: UserGuideResource
}

export function UserGuideResourceCard({resource}: UserGuideResourceCardProps) {
    const downloadFile = (resource: UserGuideResource) => {
        const linkSource = `data:application/pdf;base64,${resource.file_content}`;
        const downloadLink = document.createElement('a');
        const fileName = `${resource.name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    return (
        <Card sx={{ width: 345, maxWidth: "80%" }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {resource.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {resource.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={() => downloadFile(resource)} endIcon={<PictureAsPdfIcon />}>
                    Download
                </Button>
            </CardActions>
        </Card>
    );
}
