import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

interface IndustryCardProps {
  title: string;
  imagePath: string;
  children?: React.ReactNode;
  link?: string; // Optional link property
}

export default function IndustryCard({ title, imagePath, children, link }: IndustryCardProps) {
  return (
    <Card className="industry-item" style={{ border: "none", boxShadow: "none" }}>
      {link ? (
        <Link to={link}>
          <CardMedia
            className="industry-image"
            image={imagePath}
            component="img"
          />
        </Link>
      ) : (
        <CardMedia
          className="industry-image"
          image={imagePath}
          component="img"
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h4" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text', textAlign: "left", fontSize: 16 }}>
          {children}
        </Typography>
      </CardContent>
    </Card>
  );
}
